import { ArrowDown, ArrowUp, Open } from '@local/web-design-system-2/dist/icons';
import { DriverIcon, ViewerIcon } from '@local/web-design-system/dist/icons';
import { ListItemIcon, ListItemText, MenuList } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { OBJECT_OPEN_IN, ID_PARAM } from 'src/strings';
import { isSchemaViewable, isSchemaDriverViewable } from 'src/utils/extractSchema';
import { createNavigateToDriver, createNavigateToViewer } from 'src/utils/viewObjectUtils';

interface ObjectViewOptionsMenuProps {
    objectSchema: string;
}

export function ObjectViewOptionsMenu({ objectSchema }: ObjectViewOptionsMenuProps) {
    const [id] = usePersistedState(ID_PARAM);
    const navigate = useNavigate();

    const { hostname } = window.location;
    const params = useParams();
    const featureFlags = useFlags();

    const [popoverAnchor, setPopoverAnchor] = React.useState<Element | null>(null);
    const isOpen = Boolean(popoverAnchor);
    const Icon = isOpen ? ArrowUp : ArrowDown;

    const [menuWidth, setMenuWidth] = React.useState(0);

    const MENU_OPTIONS = [
        {
            key: 'Open in Viewer',
            ItemComponent: ViewerIcon,
            action: createNavigateToViewer(navigate, params.hubCode, params.workspaceUuid, id),
            filterWith: isSchemaViewable,
        },
        {
            key: 'Open in Driver',
            ItemComponent: DriverIcon,
            action: createNavigateToDriver(
                hostname,
                params.orgUuid,
                params.hubCode,
                params.workspaceUuid,
                id,
            ),
            filterWith: isSchemaDriverViewable,
        },
    ];

    const openMenu = (event: React.MouseEvent) => {
        setMenuWidth(event.currentTarget.clientWidth);
        setPopoverAnchor(event.currentTarget);
    };

    const closeMenu = React.useCallback(() => {
        setPopoverAnchor(null);
    }, []);

    const options = MENU_OPTIONS.filter((option) => option.filterWith(objectSchema, featureFlags));

    if (!options.length) {
        return null;
    }

    return (
        <>
            <ButtonGroup
                automation-id="open-in-plot-buttons-view"
                variant="contained"
                fullWidth
                onClick={openMenu}
            >
                <Button startIcon={<Open fontSize="small" />}>{OBJECT_OPEN_IN}</Button>
                <Button sx={{ width: '50px' }} startIcon={<Icon fontSize="large" />} />
            </ButtonGroup>
            <Menu
                id="view-object-options-menu"
                anchorEl={popoverAnchor}
                open={isOpen}
                onClose={closeMenu}
                MenuListProps={{
                    sx: { width: menuWidth },
                }}
            >
                <MenuList>
                    {options.map((option) => {
                        const { key, ItemComponent, action } = option;
                        return (
                            <MenuItem key={key} onClick={action}>
                                <ListItemIcon>
                                    <ItemComponent />
                                </ListItemIcon>
                                <ListItemText>{key}</ListItemText>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </>
    );
}
