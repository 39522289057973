import { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { TableDragAndDrop } from 'src/components/dragAndDrop/TableDragAndDrop';

function HeaderCell({ children }: React.PropsWithChildren) {
    // TODO get the correct values from the theme.
    const theme = useTheme();
    return (
        <TableCell
            sx={{
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default,
            }}
        >
            {children}
        </TableCell>
    );
}

export interface TableHeaderProps {
    fields: FieldDefinition[];
    hasCheckbox?: boolean;
    hasActions?: boolean;
    hasDragAndDrop?: boolean;
}

export const TableHeader = ({
    fields,
    hasActions = false,
    hasCheckbox = false,
    hasDragAndDrop = false,
}: TableHeaderProps) => {
    const tableCellNodes: React.ReactNode[] = [];
    fields.forEach((fieldDefinition: FieldDefinition) => {
        const { key, label } = fieldDefinition;

        tableCellNodes.push(
            <HeaderCell key={key}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{label}</Typography>
            </HeaderCell>,
        );
    });

    return (
        <>
            <TableHead>
                <TableRow>
                    {hasCheckbox && <HeaderCell />}
                    {tableCellNodes}
                    {hasActions && <HeaderCell />}
                </TableRow>
            </TableHead>
            {hasDragAndDrop ? <TableDragAndDrop colSpan={6} /> : null}
        </>
    );
};
