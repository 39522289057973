import {
    GetObjectByIdApiArg,
    GetObjectResponse,
    NamedDataDownloadUrl,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { getUrlConfig } from '@local/api-clients/dist/utils/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    LINE_DEFAULT_MODE,
    LINE_DEFAULT_WIDTH,
    MESH_DEFAULT_COLOR,
    MESH_DEFAULT_COLOR_BACK,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
    TUBE_DEFAULT_RADIUS,
} from '@local/webviz/dist/context/snapshots/defaults';
import { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, LinesMode, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import { AttributeNameColormapsMapType } from 'src/store/colormap/types';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { extractSchema } from 'src/utils/extractSchema';
import { ATTRIBUTES_PATH, Schemas } from 'src/visualization/constants';
import {
    extractTilesetUri,
    generateVisualizationServiceUrl,
    getTriangleMeshAttributes,
} from 'src/visualization/utils/snapshotUtils';

import {
    DownholeCollectionType,
    GeoscienceGridObject,
    GeoscienceLineObject,
    GeoscienceMeshObject,
    Tileset,
} from '../../types';
import { generateAttributeListSnapshot } from './attributeSnapshot';
import { CreateViewSnapshot, PointSnapshotParams } from './generateSnapshot.types';

export function getViewIdFromObjectId(objectId: string, fullSchema: string) {
    const extractedSchema = extractSchema(fullSchema);
    switch (extractedSchema) {
        case Schemas.PointsetSchema:
            return toSuffixUid(objectId, ViewClass.Points);
        case Schemas.DownholeIntervalsSchema:
            return toSuffixUid(objectId, ViewClass.Lines);
        case Schemas.GeologicalModelMeshesSchema:
        case Schemas.TriangleMeshSchema:
            return toSuffixUid(objectId, ViewClass.Surface);
        case Schemas.Regular2DGridSchema:
            return toSuffixUid(objectId, ViewClass.TensorSurface);
        default:
            return '';
    }
}

/**
 * getObjectIdFromViewId
 *
 * Since we construct our IDs in the format <object-id>:<stuff?>:<Class?>, we can extract
 * the object ID by splitting the view ID by the last colon and taking the first part.
 *
 * @param viewId the ViewID to tokenize and extract the object ID from
 * @returns string: the object ID
 */
export function getObjectIdFromViewId(viewId: string) {
    const lastIndex = viewId.lastIndexOf(':');
    return viewId.substring(0, lastIndex);
}

export function createViewSnapshot(
    treeItem: TreeStructure,
    gooseResponse: GetObjectResponse,
    params?: GetObjectByIdApiArg,
    tileset?: Tileset,
    attributeNameColormapsMap?: AttributeNameColormapsMapType,
): CreateViewSnapshot {
    let viewId = '';

    const viewSnapshot = generateSnapshot();
    if (!viewSnapshot) {
        // eslint-disable-next-line no-console
        console.warn(`Unsupported schema: ${gooseResponse.object.schema}`);
    }
    function generateSnapshot() {
        const schema = extractSchema(gooseResponse.object.schema);
        switch (schema) {
            case Schemas.DownholeCollectionSchema: {
                if (treeItem.schema === Schemas.DownholeIntervalsSchema) {
                    if (!tileset) return undefined;
                    viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);
                    return generateDownholeCollectionLinesSnapshot(
                        treeItem,
                        tileset,
                        gooseResponse.object as DownholeCollectionType,
                    );
                }
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Points);
                const coordinates = gooseResponse.object.location?.coordinates;
                if (!coordinates?.data) return undefined;
                const snapshotParams: PointSnapshotParams = {
                    objectId: gooseResponse.object_id,
                    width: coordinates.width,
                    length: coordinates.length,
                    dataType: coordinates.data_type,
                    dataId: coordinates.data,
                    data: gooseResponse.links.data,
                };
                return generatePointsSnapshot(viewId, snapshotParams);
            }
            case Schemas.TriangleMeshSchema: {
                if (!params) {
                    return undefined;
                }
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Surface);

                return generateSurfaceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceMeshObject,
                    params,
                );
            }
            case Schemas.DownholeIntervalsSchema: {
                if (!params) {
                    return undefined;
                }

                viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);
                return generateLinesSnapshot(
                    viewId,
                    gooseResponse as GeoscienceLineObject,
                    params,
                    attributeNameColormapsMap,
                );
            }
            case Schemas.PointsetSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Points);
                const coordinates = gooseResponse.object.locations?.coordinates;
                if (!coordinates?.data) return undefined;
                const snapshotParams: PointSnapshotParams = {
                    objectId: gooseResponse.object_id,
                    width: coordinates.width,
                    length: coordinates.length,
                    dataType: coordinates.data_type,
                    dataId: coordinates.data,
                    data: gooseResponse.links.data,
                };
                return generatePointsSnapshot(viewId, snapshotParams);
            }
            case Schemas.Regular2DGridSchema: {
                if (!params) {
                    return undefined;
                }
                viewId = toSuffixUid(treeItem.treeId, ViewClass.TensorSurface);
                return generateTensorSurfaceSnapshot(
                    viewId,
                    gooseResponse as GeoscienceGridObject,
                    params,
                );
            }
            case Schemas.GeologicalModelMeshesSchema: {
                if (!tileset) return undefined;
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Surface);
                return generateGMMSurfaceSnapshot(viewId, treeItem, tileset);
            }
            default:
                return undefined;
        }
    }

    return {
        viewId,
        snapshot: viewSnapshot,
    };
}

function generateTensorSurfaceSnapshot(
    viewId: string,
    gooseObject: GeoscienceGridObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot {
    const { object_id: objectId } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.TensorSurface, {
            id: viewId,
            element: elementId,
        }),
    };
}

function generateSurfaceSnapshot(
    viewId: string,
    gooseObject: GeoscienceMeshObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);
    const attributes = getTriangleMeshAttributes(object);

    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(attributes);
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            wireframe: false,
            showFaces: true,
            color: MESH_DEFAULT_COLOR,
            color_back: MESH_DEFAULT_COLOR_BACK,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}

function generatePointsSnapshot(
    viewId: string,
    gooseObject: PointSnapshotParams,
): UpdateSnapshot | undefined {
    const { objectId, width, length, dataType, dataId, data } = gooseObject;

    const dataObject = data.find(
        (dataDownloadUrl) =>
            dataDownloadUrl.id === dataId ||
            (dataDownloadUrl as NamedDataDownloadUrl).name === dataId,
    );
    if (!dataObject?.download_url) {
        return undefined;
    }

    const elementId = toSuffixUid(objectId, ElementClass.Points);
    const snapshot = {
        [elementId]: generateEntity(ElementClass.Points, {
            verticesUrl: {
                location: dataObject.download_url,
                shape: [length, width],
                dataType,
            },
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
        }),
    };
    return snapshot;
}

function generateLinesSnapshot(
    viewId: string,
    gooseObject: GeoscienceLineObject,
    params: GetObjectByIdApiArg,
    attributeNameColormapsMap?: AttributeNameColormapsMapType,
): UpdateSnapshot {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        object[ATTRIBUTES_PATH],
        attributeNameColormapsMap,
        ATTRIBUTES_PATH,
    );

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            width: LINE_DEFAULT_WIDTH,
            mode: LINE_DEFAULT_MODE,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}

function generateGMMSurfaceSnapshot(
    viewId: string,
    treeItem: TreeStructure,
    tileset: Tileset,
): UpdateSnapshot | undefined {
    const url = extractTilesetUri(tileset, treeItem);

    if (!url) {
        return undefined;
    }

    const elementId = toSuffixUid(treeItem.treeId, ElementClass.Tileset3D);

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            id: elementId,
            url,
        }),
        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            wireframe: false,
            showFaces: true,
            color: MESH_DEFAULT_COLOR,
            color_back: MESH_DEFAULT_COLOR_BACK,
        }),
    };
}

function generateDownholeCollectionLinesSnapshot(
    treeItem: TreeStructure,
    tileset: Tileset,
    gooseObject: DownholeCollectionType,
): UpdateSnapshot | undefined {
    const url = extractTilesetUri(tileset, treeItem);

    if (!url) {
        return undefined;
    }

    const elementId = toSuffixUid(treeItem.treeId, ElementClass.Tileset3D);
    const viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);

    const collectionObj = gooseObject.collections.find(
        (collection) => collection.name === treeItem.name,
    );
    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        collectionObj?.from_to?.attributes,
    );

    const snapshot = {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            id: elementId,
            url,
        }),
        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            mode: LinesMode.Tubes,
            radius: TUBE_DEFAULT_RADIUS,
            width: LINE_DEFAULT_WIDTH,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };

    return snapshot;
}
