import CloseIcon from '@local/web-design-system-2/dist/icons/Close';
import { useGetCurrentUserRoleQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import { User } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';

import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { UNKNOWN_USERNAME, CLEAR_ALL } from 'src/strings';
import { dateRangeToString } from 'src/utils/filtering';

import { useStyles } from './ObjectFilterPills.styles';

const Pill = ({ label, onDelete }: { label: string; onDelete?: (event?: any) => void }) => (
    <Button
        automation-id="go-filter-pill"
        // inline styling used as classes don't seem to apply
        // to this button
        style={{
            fontSize: '12px',
            borderRadius: '20px',
            padding: '6px 10px',
            textTransform: 'none',
            backgroundColor: '#E1E0E0',
            margin: '4px',
        }}
        key={label}
        onClick={onDelete}
    >
        {label} <CloseIcon style={{ width: 10, height: 10, paddingLeft: 10 }} />
    </Button>
);

const Loading = () => (
    <>
        <Skeleton variant="rectangular" width="110px" height="26px" />
        <Skeleton variant="rectangular" width="110px" height="26px" />
        <Skeleton variant="rectangular" width="110px" height="26px" />
    </>
);

export const ObjectFilterPills = ({
    userData,
    isLoading,
}: {
    userData: User[];
    isLoading: boolean;
}) => {
    const {
        typeParams,
        removeTypeParam,
        updatedByParams,
        removeUpdatedOnParam,
        updatedOnParams,
        clearAllParams,
        removeUpdatedByParam,
    } = useObjectFilterParams();

    const { classes } = useStyles();
    const params = useParams();

    const { data: currentUserRoleData } = useGetCurrentUserRoleQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });

    const getUserName = (user_id: string) =>
        currentUserRoleData?.user_id === user_id
            ? `${userData.find((user) => user.user_id === user_id)?.full_name} (you)`
            : userData.find((user) => user.user_id === user_id)?.full_name || UNKNOWN_USERNAME;

    const validDateRange = updatedOnParams.startDate && updatedOnParams.endDate;
    const numFiltersApplied = (validDateRange ? 1 : 0) + updatedByParams.length + typeParams.length;

    const filterPills = () => (
        <>
            {typeParams.map((value) => (
                <Pill
                    key={value}
                    label={`${value}`}
                    onDelete={() => {
                        removeTypeParam(value);
                    }}
                />
            ))}
            {isLoading && <Loading />}
            {!isLoading &&
                updatedByParams.map((user_id) => (
                    <Pill
                        key={user_id}
                        label={`${getUserName(user_id)}`}
                        onDelete={() => {
                            removeUpdatedByParam(user_id);
                        }}
                    />
                ))}
            {validDateRange && (
                <Pill label={dateRangeToString(updatedOnParams)} onDelete={removeUpdatedOnParam} />
            )}
        </>
    );

    return (
        <Box>
            <Collapse in={numFiltersApplied > 0}>
                <Grid
                    container
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    gap={1}
                    className={classes.optionsContainer}
                >
                    {filterPills()}
                    <Button
                        className={classes.clearAllButton}
                        onClick={clearAllParams}
                        automation-id="go-filter-clear-all-button"
                    >
                        {CLEAR_ALL}
                    </Button>
                </Grid>
            </Collapse>
        </Box>
    );
};
